import { Injectable } from '@angular/core';

/**
 * Date/Time Service for use with CalendarDatePicker & RadialTimePicker.
 */
@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor() {}

  /**
   * Split a timestamp (in seconds) into a Date value compatible with
   * CalendarDatePicker and a Formatted Time value compatible with
   * RadialTimePicker.
   */
  splitDateAndTime(
    timestampInSeconds: number,
    isUtcDate?: boolean
  ): [Date, string] {
    const date = new Date(timestampInSeconds * 1000);

    const utcDate = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

    return isUtcDate ? [utcDate, formattedTime] : [date, formattedTime];
  }

  /**
   * Convert a date/formattedTime like the ones from splitDateAndTime
   * back into a timestamp (in seconds).
   */
  getTimestampInSecondsFromDateAndTime(date: Date, time: string): number {
    if (!date || !time) {
      return;
    }

    const [hours, minutes, period] = time.split(/:|\s/);

    date.setUTCHours((parseInt(hours, 10) % 12) + (period === 'PM' ? 12 : 0));
    date.setUTCMinutes(parseInt(minutes, 10));
    date.setUTCSeconds(0);

    return Math.round(date.getTime() / 1000);
  }
}
