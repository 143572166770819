import { Injectable } from '@angular/core';
import {
  MenuConfig,
  MenuOption,
  OptionConfig,
  SectionConfig,
} from './menu/menu.model';
import { AuthService } from '../core/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { DataStore } from './dashboard.model';
import { APIService } from '../core/API.service';
import { GtmUtilsService } from '../shared/gtm-utils.service';
import {
  homeSection,
  requestListSection,
  settingsSection,
  shiftLogsSection,
  bulkUploadSection,
  ncrSection,
  supportSection,
  reportsSection,
  scheduleSection,
  allRoles,
  rvpDepotRole,
  depotRole,
  logOutSection,
  managerRole,
  otherToolsSection,
} from './dashboard.menu';

@Injectable()
export class DashboardService {
  private _breakpointFlag = new BehaviorSubject(true);
  currentBreakpoint = this._breakpointFlag.asObservable();
  shouldDisplaySchneiderCompletionOnMenu: boolean = false;

  constructor(
    private authService: AuthService,
    private api: APIService,
    private gtmUtilsService: GtmUtilsService
  ) {}

  public dataStore: DataStore = {
    businessPartners: [],
    businessPartnersNCR: [],
  };

  isRoleNamePresent(roleName: string, availableTo: string[]): boolean {
    if (availableTo.includes(allRoles)) {
      return true;
    }
    const availableRole = availableTo.find((role) => {
      // If it's only Depot without being RVP, using a single condition for Depot
      // Case because it can include in a RVP Depot role string
      if (
        roleName.includes(depotRole) &&
        !role.includes(rvpDepotRole) &&
        roleName.includes(role)
      ) {
        return true;
      }
      return roleName.includes(role);
    });

    return availableRole !== undefined && availableRole !== null;
  }

  getNcrAvailableSections(
    roleName: string,
    section: SectionConfig,
    hasAllReportActions: boolean,
    isQualaWorker: boolean
  ): SectionConfig {
    if (isQualaWorker && hasAllReportActions) {
      return section;
    }

    if (isQualaWorker && roleName.includes(managerRole)) {
      return {
        ...section,
        options: section.options.filter(
          (option) => option.value !== MenuOption.NcrOpened
        ),
      };
    }

    return this.getAvailableSections(roleName, section);
  }

  getRequestListAvailableSections(
    roleName: string,
    section: SectionConfig,
    isQualaWorker: boolean
  ): SectionConfig {
    if (isQualaWorker) {
      section = {
        ...section,
        options: section.options.filter(
          (option) => option.value !== MenuOption.SchneiderPortalCompletions
        ),
      };
    }
    return this.getAvailableSections(roleName, section);
  }

  getAvailableSections(
    roleName: string,
    section: SectionConfig | OptionConfig
  ): SectionConfig | OptionConfig {
    const isRootSectionAvailable =
      section.availableTo.includes(allRoles) ||
      this.isRoleNamePresent(roleName, section.availableTo);

    if (!isRootSectionAvailable) {
      return;
    }

    return {
      ...section,
      options: section?.options?.filter((option) =>
        this.isRoleNamePresent(roleName, option.availableTo)
      ),
    };
  }

  getSettingsAvailableSections(
    roleName: string,
    section: SectionConfig,
    isTerminalActive: boolean,
    isQualaWorker: boolean
  ): SectionConfig {
    let availableSections = this.getAvailableSections(roleName, section);

    if (isQualaWorker && isTerminalActive && availableSections) {
      availableSections = {
        ...availableSections,
        options: availableSections?.options?.filter(
          (option) => option.value !== MenuOption.ActivateTerminal
        ),
      };
    }

    return availableSections;
  }

  getScheduleAvailableSections(
    roleName: string,
    section: SectionConfig,
    isTerminalActive: boolean,
    isQualaWorker: boolean
  ): SectionConfig {
    let availableSections = this.getAvailableSections(roleName, section);

    if (isQualaWorker && !isTerminalActive) {
      return;
    }

    return availableSections;
  }

  getMenuConfig(): MenuConfig {
    const menu = new MenuConfig();
    const hasAllReportActions = this.authService.hasAllReportActions();
    const isQualaWorker = this.authService.hasQualaWorkerRole();
    const isTerminalActive = this.authService?.user?.currentTerminal?.active;
    const currentRole = this.authService?.user?.currentRole;

    menu.sections.push(
      ...[
        this.getAvailableSections(currentRole, homeSection),
        this.getRequestListAvailableSections(
          currentRole,
          requestListSection,
          isQualaWorker
        ),
        this.getScheduleAvailableSections(
          currentRole,
          scheduleSection,
          isTerminalActive,
          isQualaWorker
        ),
        this.getAvailableSections(currentRole, shiftLogsSection),
        this.getNcrAvailableSections(
          currentRole,
          ncrSection,
          hasAllReportActions,
          isQualaWorker
        ),
        this.getAvailableSections(currentRole, reportsSection),
        this.getAvailableSections(currentRole, bulkUploadSection),
        this.getSettingsAvailableSections(
          currentRole,
          settingsSection,
          isTerminalActive,
          isQualaWorker
        ),
        this.getAvailableSections(currentRole, supportSection),
        this.getAvailableSections(currentRole, otherToolsSection),
        this.getAvailableSections(currentRole, logOutSection),
      ]
    );

    menu.sections = menu.sections.filter((section) => section !== undefined);

    return menu;
  }

  changeBreakpointFlag(flag: boolean) {
    this._breakpointFlag.next(flag);
  }
}
