import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SectionConfig, MenuOption, OptionConfig } from './menu.model';
import { Router, ActivatedRoute, NavigationEnd, Routes } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { FileItemService } from 'src/app/shared/file-upload/file-item/file-item.service';
import { LocalStorageService } from '../../core/localstorage.service';
import { User } from '../../core/model/user.model';
import { environment } from 'src/environments/environment';
import { WashListService } from 'src/app/dashboard/wash-list/wash-list.service';
import { BulkUploadComponent } from '../bulk-upload/bulk-upload.component';
import { DatalakeComponent } from '../platform-other-tools/datalake/datalake.component';

@Component({
  selector: 'app-menu-section',
  templateUrl: './menu-section.component.html',
  styleUrls: ['./menu-section.component.scss'],
})
export class MenuSectionComponent implements OnInit {
  showSubItems: boolean;
  selectedComponent: any;

  @Input() hideSubItems: boolean;
  @Input() section: SectionConfig | OptionConfig;
  @Input() isFirstLevelOption: boolean;
  @Output() selected = new EventEmitter<string>();

  selectedOption: string;

  private user: User;

  get label(): string {
    return (this.section && this.section.label) || 'Not Defined';
  }

  get icon(): string {
    return (this.section && this.section.icon) || '';
  }

  removeItemsFromScheduleSubMenu(item) {
    this.section.options.forEach((option, index) => {
      if (option.label === item) {
        this.section.options.splice(index, 1);
      }
    });
  }

  get subItems(): Array<SectionConfig> {
    if (
      this.section &&
      this.section.options &&
      Array.isArray(this.section.options)
    ) {
      if (this.user.currentTerminal && !this.user.currentTerminal.active) {
        this.removeItemsFromScheduleSubMenu('Schedule');
      }
      return this.section.options;
    }
    return [];
  }

  get showSubItemsValue() {
    if (!this.hideSubItems) {
      this.showSubItems = false;
    }

    return this.showSubItems;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private fileService: FileItemService,
    private localStorageService: LocalStorageService,
    private washListService: WashListService
  ) {}

  ngOnInit() {
    this.showSubItems = false;
    this.user = this.localStorageService.getUser();
    this.checkSelection();

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.checkSelection();
      }
    });

    if (this.section.value === 'wash-list') {
      this.washListService.countMap.subscribe((countMap) => {
        this.section.options.forEach((subItem) => {
          if (countMap.total[subItem.value]) {
            subItem.totalCount = countMap.total[subItem.value];
          }
          if (countMap.new[subItem.value]) {
            subItem.newCount = countMap.new[subItem.value];
          }
        });
      });
    }
  }

  private checkSelection() {
    const url = this.router.url;
    const path = url.split('?')[0];
    const segments = path.split('/').filter((segment) => segment.length > 0);

    if (url.includes('home')) {
      this.selectedOption = MenuOption.Home;
    }

    if (url.includes('wash-list')) {
      // tslint:disable-next-line:no-string-literal
      this.selectedOption = segments[2] || MenuOption.Draft;
    }

    if (url.includes('schedule')) {
      this.selectedOption = MenuOption.Schedule;
    }

    if (url.includes('settings/activate-terminal')) {
      this.selectedOption = MenuOption.ActivateTerminal;
    }
    if (url.includes('settings/hours-of-operation-settings')) {
      this.selectedOption = MenuOption.HoursOfOperation;
    }
    if (url.includes('settings/bay-settings')) {
      this.selectedOption = MenuOption.BaySettings;
    }
    if (url.includes('settings/velocity-settings')) {
      this.selectedOption = MenuOption.VelocitySettings;
    }

    if (url.includes('reports')) {
      this.selectedOption = MenuOption.Reports;
    }

    if (url.includes('wash-metrics')) {
      this.selectedOption = MenuOption.WashMetrics;
    }

    if (url.includes('lean-daily-management')) {
      this.selectedOption = MenuOption.LeanDailyManagementReport;
    }

    if (url.includes('terminal-scorecard-wash')) {
      this.selectedOption = MenuOption.TerminalScorecardWash;
    }

    if (url.includes('terminal-scorecard-depot')) {
      this.selectedOption = MenuOption.TerminalScorecardDepot;
    }

    if (url.includes('terminal-scorecard-industrial')) {
      this.selectedOption = MenuOption.TerminalScorecardIndustrial;
    }

    if (url.includes('terminal-scorecard-rail')) {
      this.selectedOption = MenuOption.TerminalScorecardRail;
    }

    if (url.includes('non-conformity-report')) {
      const searchParams = new URLSearchParams(window.location.search);
      // tslint:disable-next-line:no-string-literal
      const segment = segments[3];
      const option =
        searchParams.get('option') || (segment && segment.toString());

      this.selectedOption =
        option && Object.values(MenuOption).includes(option as MenuOption)
          ? option
          : MenuOption.NcrOpened;
    }

    if (url.includes('shift-logs')) {
      this.selectedOption = MenuOption.ShiftLogs;
    }

    if (url.includes('business-partner-notification')) {
      this.selectedOption = MenuOption.BusinessPartnerNotification;
    }

    if (url.includes('user-notification')) {
      this.selectedOption = MenuOption.UserNotification;
    }

    if (url.includes('exterior-wash-offer')) {
      this.selectedOption = MenuOption.ExteriorWashOffer;
    }

    if (url.includes('support/terms-and-conditions')) {
      this.selectedOption = MenuOption.Terms;
    }
  }

  toggleDisplay() {
    this.showSubItems = !this.showSubItems;
  }

  itemSelected(option: string) {
    if (!this.section.value || !option) {
      return;
    }
    switch (this.section.value) {
      case 'wash-list':
        this.selectedOption = option;
        this.router.navigate([`/dashboard/${this.section.value}`, option]);
        break;

      case 'home':
      case 'schedule':
        this.router.navigate([`/dashboard/${this.section.value}`]);
        break;

      case 'settings':
      case 'reports':
      case 'wash-metrics':
      case 'terminal-scorecard-wash':
      case 'shift-logs':
        this.router.navigate([`/dashboard/${option}`]);
        break;

      case 'bulk-upload':
        if (option === MenuOption.DownloadTemplate) {
          this.router.navigate([`/dashboard/${BulkUploadComponent.route}`]);
        }
        break;

      case 'other-tools':
        if (option === MenuOption.OtherToolsDatalake) {
          this.router.navigate([`/dashboard/${DatalakeComponent.route}`]);
        }
        break;

      case 'support':
        if (option === 'wiki') {
          window.open(environment.wikiUrl);
        }
        if (option === 'video') {
          window.open(environment.trainingVideoUrl);
        } else if (option === MenuOption.Terms) {
          this.router.navigate([
            `/dashboard/${this.section.value}/terms-and-conditions`,
          ]);
        }
        break;

      case 'non-conformity-report':
        this.router.navigate([`/dashboard/${this.section.value}/list`, option]);
        break;

      case 'exterior-wash-offer':
        this.selectedOption = option;
        this.router.navigate([`/dashboard/${this.section.value}/`]);
        break;

      case 'logout':
        if (option === MenuOption.Logout) {
          this.router.navigate([`/logout`]);
        }
    }
  }
}
