import { MenuOption, OptionConfig, SectionConfig } from './menu/menu.model';
import { WashListComponent } from './wash-list/wash-list.component';
export const managerRole = '-Manager';
export const depotRole = 'Depot';
export const rvpDepotRole = 'RVP Depot';
export const ticketRole = 'Ticket';
export const cscRole = 'CSC';
export const cleanerRole = 'Cleaner';
export const dispatcherRole = 'Customer';
export const allRoles = 'allRoles';

export const logOutSection: OptionConfig = {
  label: 'Logout',
  icon: 'sign-out-alt',
  value: MenuOption.Logout,
  availableTo: [allRoles],
};

export const homeSection: SectionConfig = {
  label: 'Home',
  icon: 'home',
  value: MenuOption.Home,
  availableTo: [allRoles],
  options: [
    {
      label: 'Home',
      icon: 'home',
      value: MenuOption.Home,
      availableTo: [allRoles],
    },
  ],
};

export const otherToolsSection: SectionConfig = {
  label: 'Other Tools',
  icon: 'tools',
  value: MenuOption.OtherTools,
  availableTo: [allRoles],
  options: [
    {
      label: 'Datalake',
      icon: 'water',
      value: MenuOption.OtherToolsDatalake,
      availableTo: [allRoles],
    },
  ],
};

export const requestListSection: SectionConfig = {
  label: 'OnTrax Requests',
  icon: 'list-ul',
  value: WashListComponent.route,
  availableTo: [managerRole, dispatcherRole, cscRole, ticketRole, cleanerRole],
  options: [
    {
      label: 'Exterior Wash Offer',
      icon: 'tint',
      value: MenuOption.ExteriorWashOffer,
      availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
    },
    {
      label: 'Drafts',
      icon: 'edit',
      value: MenuOption.Draft,
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Submitted',
      icon: 'paper-plane',
      value: MenuOption.Submitted,
      availableTo: [dispatcherRole],
    },
    {
      label: 'New Requests',
      icon: 'plus-circle',
      value: MenuOption.NewWashRequests,
      availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
    },
    {
      label: 'Pending',
      icon: 'hourglass-half',
      value: MenuOption.Pending,
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Accepted',
      icon: 'star',
      value: MenuOption.Accepted,
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Credit Hold',
      icon: 'credit-card',
      value: MenuOption.CreditHold,
      availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
    },
    {
      label: 'Needs Action',
      icon: 'exclamation-circle',
      value: MenuOption.NeedsAction,
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'In Progress',
      icon: 'sync-alt',
      value: MenuOption.InProgress,
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Hold',
      icon: 'hand-paper',
      value: MenuOption.Hold,
      availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
    },
    {
      label: 'Completed',
      icon: 'check-circle',
      value: MenuOption.Completed,
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Canceled',
      icon: 'ban',
      value: MenuOption.Canceled,
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Rejected',
      icon: 'times-circle',
      value: MenuOption.Rejected,
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Expired Requests',
      icon: 'hourglass-end',
      value: MenuOption.Expired,
      availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
    },
    {
      label: 'Schneider Portal Completions',
      icon: 'truck',
      value: MenuOption.SchneiderPortalCompletions,
      availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
    },
  ],
};

export const scheduleSection: SectionConfig = {
  label: 'OnTrax Scheduling',
  icon: 'calendar',
  value: MenuOption.Schedule,
  availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
  options: [
    {
      label: 'Schedule',
      icon: 'calendar',
      value: MenuOption.Schedule,
      availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
    },
  ],
};

export const settingsSection: SectionConfig = {
  label: 'Settings',
  icon: 'cog',
  value: 'settings',
  availableTo: [managerRole, dispatcherRole, cscRole, ticketRole, cleanerRole],
  options: [
    {
      label: 'Notification Preferences',
      icon: 'bell',
      value: `settings/${MenuOption.UserNotification}`,
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Company Notifications',
      icon: 'bell',
      value: `settings/${MenuOption.BusinessPartnerNotification}`,
      availableTo: [dispatcherRole],
    },
    {
      label: 'Terminal Bays',
      icon: 'truck',
      value: MenuOption.BaySettings,
      availableTo: [managerRole],
    },
    {
      label: 'Terminal Shifts',
      icon: 'clock',
      value: MenuOption.HoursOfOperation,
      availableTo: [managerRole],
    },
    {
      label: 'Terminal Velocity',
      icon: 'tachometer-alt',
      value: MenuOption.VelocitySettings,
      availableTo: [managerRole],
    },
    {
      label: 'Activate Terminal',
      icon: 'check-circle',
      value: MenuOption.ActivateTerminal,
      availableTo: [managerRole],
    },
  ],
};

export const reportsSection: SectionConfig = {
  icon: 'chart-line',
  label: 'OnTrax Reports',
  value: 'reports',
  availableTo: [managerRole, dispatcherRole],
  options: [
    {
      icon: 'chart-line',
      label: 'Wash Metrics',
      value: MenuOption.WashMetrics,
      availableTo: [dispatcherRole],
    },
    {
      icon: 'chart-line',
      label: 'Lean Daily Management',
      value: MenuOption.LeanDailyManagementReport,
      availableTo: [managerRole],
    },
    {
      icon: 'chart-line',
      label: 'Terminal Reports',
      value: MenuOption.Reports,
      availableTo: [managerRole],
    },
    {
      icon: 'soap',
      label: 'Terminal Scorecard - Tank Wash & IBC',
      value: MenuOption.TerminalScorecardWash,
      availableTo: [managerRole],
    },
    {
      icon: 'truck',
      label: 'Terminal Scorecard - Depot & Transportation',
      value: MenuOption.TerminalScorecardDepot,
      availableTo: [managerRole],
    },
    {
      icon: 'industry',
      label: 'Terminal Scorecard - Industrial Services',
      value: MenuOption.TerminalScorecardIndustrial,
      availableTo: [managerRole],
    },
    {
      icon: 'train',
      label: 'Terminal Scorecard - Rail & Specialty',
      value: MenuOption.TerminalScorecardRail,
      availableTo: [managerRole],
    },
    {
      icon: 'recycle',
      label: 'Waste Management',
      value: MenuOption.EhsWasteManagement,
      availableTo: [managerRole],
    },
    {
      icon: 'external-link',
      label: 'Reports Portal',
      value: MenuOption.ReportsPortal,
      availableTo: [managerRole],
    },
  ],
};

export const shiftLogsSection: SectionConfig = {
  icon: 'table',
  label: 'OnTrax Shift Logs',
  value: MenuOption.ShiftLogs,
  availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
  options: [
    {
      icon: 'table',
      label: 'Terminal Shift Logs',
      value: MenuOption.ShiftLogs,
      availableTo: [managerRole, cscRole, ticketRole, cleanerRole],
    },
  ],
};

export const bulkUploadSection: SectionConfig = {
  label: 'Bulk Upload',
  icon: 'cloud-upload-alt',
  value: 'bulk-upload',
  availableTo: [managerRole, dispatcherRole, cscRole, ticketRole, cleanerRole],
  options: [
    {
      label: 'Download Template',
      icon: 'file-download',
      value: 'download-template',
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
  ],
};

export const supportSection: SectionConfig = {
  label: 'Support',
  icon: 'question-circle',
  value: 'support',
  availableTo: [allRoles],
  options: [
    {
      label: 'User Guide',
      icon: 'book',
      value: 'wiki',
      availableTo: [
        managerRole,
        depotRole,
        rvpDepotRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Customer Training Video',
      icon: 'video',
      value: 'video',
      availableTo: [
        managerRole,
        dispatcherRole,
        cscRole,
        ticketRole,
        cleanerRole,
      ],
    },
    {
      label: 'Terms and Conditions',
      icon: 'book',
      value: MenuOption.Terms,
      availableTo: [allRoles],
    },
  ],
};

export const ncrSection: SectionConfig = {
  label: 'OnTrax NCR',
  icon: 'file',
  value: 'non-conformity-report',
  availableTo: [allRoles],
  options: [
    {
      label: 'Opened',
      icon: 'folder-open',
      value: MenuOption.NcrOpened,
      availableTo: [dispatcherRole, rvpDepotRole, managerRole],
    },
    {
      label: 'In Analysis',
      icon: 'chart-bar',
      value: MenuOption.NcrInAnalysis,
      availableTo: [
        depotRole,
        ticketRole,
        cscRole,
        cleanerRole,
        rvpDepotRole,
        managerRole,
      ],
    },
    {
      label: 'Waiting for Approval',
      icon: 'sync-alt',
      value: MenuOption.NcrWaitingForApproval,
      availableTo: [
        depotRole,
        ticketRole,
        cscRole,
        cleanerRole,
        rvpDepotRole,
        managerRole,
      ],
    },
    {
      label: 'Completed',
      icon: 'check-circle',
      value: MenuOption.NcrCompleted,
      availableTo: [allRoles],
    },
    {
      label: 'Canceled',
      icon: 'ban',
      value: MenuOption.Canceled,
      availableTo: [allRoles],
    },
  ],
};
