export class User {
  currentRole: string;
  currentRoleAcronym: string;
  roles: any[];
  businessPartnerId: string;
  customerMasterId?: number;
  businessPartnerName: string;
  currentTerminal: CurrentTerminal;
  poFormat?: string;
  poFormatHelp?: string;
  username: string;
  name?: string;
  id: string;
  customerBlocking: string;
  hasFullReportAccess?: boolean;
  userPlatformActions?: any;
  email?: string;
  shouldCallUserInfo?: boolean;
  terminal?: string;
  terminalDisplay?: string;
  displayInfo?: string;

  constructor(data: any = {}) {
    this.currentRole = data.currentRole;
    this.currentRoleAcronym = data.currentRoleAcronym;
    this.roles = data.roles;
    this.businessPartnerId = data.businessPartner && data.businessPartner.id;
    this.businessPartnerName =
      data.businessPartner && data.businessPartner.name;
    this.customerBlocking =
      data.businessPartner && data.businessPartner.customerBlocking;
    this.username = data.username;
    this.currentTerminal = data.currentTerminal;
    this.id = data.id;
    this.poFormat =
      data.businessPartner && data.businessPartner.contmngPOFormat;
    this.poFormatHelp =
      data.businessPartner && data.businessPartner.contmngPOFormathelp;
    this.hasFullReportAccess = !!data.hasFullReportAccess || false;
    this.userPlatformActions = data.userPlatformActions;
    this.email = data.email;
    this.shouldCallUserInfo = data.shouldCallUserInfo;
    this.terminal = data.terminal;
    this.terminalDisplay = data.terminalDisplay;
    this.name = data.name;
    this.customerMasterId = data.customerMasterId;
  }
}

export class CurrentTerminal {
  id: string;
  key: string;
  number: string;
  timezone: number;
  active: boolean;
  obIdentifier: string;
  operationalRegion: string;
  platformActions?: { washSchedule: boolean; ncr: boolean };
  linesOfBusiness: string[];

  constructor(data: any = {}) {
    this.id = data.id;
    this.key = data.key;
    this.number = data.number;
    this.timezone = data.timezone;
    this.operationalRegion = data.operationalRegion;
    this.linesOfBusiness = data.linesOfBusiness;
    this.platformActions = data.platformActions;
  }
}
