import { Component, OnInit } from '@angular/core';
import { ScheduleService } from './schedule.service';
import { WashCreateComponent } from '../wash-list/wash-create/wash-create.component';
import { WashListService } from '../wash-list/wash-list.service';
import { columnWidth } from '../schedule/schedule.model';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {
  scheduleService: ScheduleService;
  defaultColumnWidth: number = columnWidth;
  fallbackBayListLength = 6;
  constructor(
    scheduleService: ScheduleService,
    private washListService: WashListService
  ) {
    this.scheduleService = scheduleService;
  }

  createNewRequest() {
    this.washListService.openCreateForm(WashCreateComponent);
  }

  ngOnInit() {
    this.scheduleService.loadInitialData();
  }
}
